import React, { createContext, useState, useEffect, useContext } from "react";

// Crear el contexto
export const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

// Crear el proveedor del contexto
export const AppProvider = ({ children }) => {
  const [location, setLocationState] = useState({ id: 1167, name: "ABASOLO" });
  const [primaryColor, setPrimaryColor] = useState("");
  const [campains, setCampains] = useState([]); // Nuevo estado para campains

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocationId = localStorage.getItem("locationId");
      const storedLocationName = localStorage.getItem("locationName");
      const storedPrimaryColor = localStorage.getItem("primaryColor");

      if (storedLocationId && storedLocationName) {
        setLocationState({ id: storedLocationId, name: storedLocationName });
      }

      if (storedPrimaryColor) {
        setPrimaryColor(storedPrimaryColor);
      }
    }
  }, []);

  const setLocation = (location) => {
    setLocationState(location);
    localStorage.setItem("locationId", location.id);
    localStorage.setItem("locationName", location.name);
  };

  const setColor = (color) => {
    setPrimaryColor(color);
    localStorage.setItem("primaryColor", color);
  };

  const setCampaignsInContext = (campains) => {
    setCampains(campains);
  };

  return (
    <AppContext.Provider value={{ location, setLocation, primaryColor, setColor, campains, setCampaignsInContext }}>
      {children}
    </AppContext.Provider>
  );
};
